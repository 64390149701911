import type { Customer } from '@dreamshaper/ds-interface'

export default defineNuxtRouteMiddleware((from) => {
  const { customer } = useUser()

  if (!customer.value) {
    const regexOrgId = /^\/customer\/([^/]+)\/?/

    const match = from.path.match(regexOrgId)

    if (match) {
      customer.value = {
        id: match[1],
      } as Customer
    } else {
      return navigateTo('/customer')
    }
  }
})
